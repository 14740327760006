import { Web3Provider } from '@ethersproject/providers'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider, useDispatch } from 'react-redux'
import { NetworkContextName } from './constants'
import 'inter-ui'
import './i18n'
import App from './pages/App'
import store from './state'
import ApplicationUpdater from './state/application/updater'
import TransactionUpdater from './state/transactions/updater'
import ListsUpdater from './state/lists/updater'
import UserUpdater from './state/user/updater'
import MulticallUpdater from './state/multicall/updater'
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme'
import { updateUserDarkMode } from './state/user/actions'
import { AppDispatch } from './state/index'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

let listenerAttached = false

if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
  const isMetamask = window.ethereum.isMetaMask
  // Handle new Ethereum account from iframe
  if (!listenerAttached) {
    listenerAttached = true
    window.addEventListener('message', async event => {
      // IMPORTANT: check the origin of the data!
      // The data was sent from your site.
      // Data sent with postMessage is stored in event.data:
      if (event.data.type === 'ethAddress') {
        const acc = await (window.ethereum as any).request({ method: 'eth_requestAccounts' })
        if (isMetamask && String(acc[0]).toLowerCase() !== String(event.data?.address).toLowerCase()) {
          window.parent.postMessage('goToManage', event.origin)
        }
      }
    })
  }
}

function useDarkMode() {
  const dispatch = useDispatch<AppDispatch>()
  dispatch(updateUserDarkMode({ userDarkMode: true }))
}

if (window.top != window.self) {
  ;(document.getElementsByTagName('body')[0] as any).style = 'background-image: none;'
  ;(document.getElementsByTagName('html')[0] as any).style = 'background-color: transparent;'
}

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000
  return library
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: false })
}

window.addEventListener('error', error => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true
  })
})

function Updaters() {
  useDarkMode()
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

ReactDOM.render(
  <>
    <FixedGlobalStyle />
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <ThemeProvider>
            <>
              <ThemedGlobalStyle />
              <App />
            </>
          </ThemeProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </>,
  document.getElementById('root')
)
