import React from 'react'
import { Wrapper } from '../../components/swap/styleds'

export default function Manage() {
  ;(window.ethereum as any).on('accountsChanged', () => {
    // @ts-ignore
    if (window.opener) {
      window.opener.postMessage('closeManage', '*')
    }
  })

  return (
    <>
      <Wrapper id="manage-page">
        <div style={{ textAlign: 'center', width: '100%', fontSize: '2rem', color: 'white' }}>
          Open MetaMask and connect your new wallet.
        </div>
      </Wrapper>
    </>
  )
}
